import React, { useState } from 'react';
import './App.scss';
import ChatWindow from './components/ChatWindow';
import { PersonasList, Persona } from './components/PersonasList';

const personas: Persona[] = [
	{
		id: 'techie-turtle',
		name: 'Techie Turtle',
		description: 'A wise, ancient entity blending technology and nature.'
	},
	{
		id: 'gourmet-gpt',
		name: 'Gourmet GPT',
		description: 'As a high-class server, I describe dishes with luxury and elegance.'
	},
	{
		id: 'therapist-gpt',
		name: 'Therapist GPT',
		description: 'A compassionate, empathetic virtual therapist.'
	},
	{
		id: 'cipher-gpt',
		name: 'CipherGPT',
		description: 'Decode the Enigmatic World of Cryptography through this Cipher Companion.'
	}
]

function App() {
	const [persona, setPersona] = useState(personas[0]);
	const [isPersonasListOpen, setIsPersonasListOpen] = useState(false);

	const selectPersona = (persona: Persona) => {
		setPersona(persona);
		setIsPersonasListOpen(false);
	}

	return (
		<div className='content-container'>
			<div className={`personas-container ${isPersonasListOpen ? 'open' : 'closed'}`}>
				<PersonasList personas={personas} selectPersona={selectPersona} />
			</div>
			<ChatWindow persona={persona} togglePersonas={() => setIsPersonasListOpen(!isPersonasListOpen)} />
		</div>
	);
}

export default App;
