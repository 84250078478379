import React from 'react';
import '../styles/PersonasList.scss';

export interface Persona {
    id: string;
    name: string;
    description: string;
}

interface PersonasListProps {
    personas: Persona[];
    selectPersona: (persona: Persona) => void;
}

export const PersonaTile = ({ persona, selectPersona }: any) => {
    return <div
    key={persona.id}
    className="persona"
    onClick={() => selectPersona(persona)}>
        <img className='profile-image' src={`/profile-photos/${persona.id}.png`} alt={persona.name} />
        <div>
            <h3>{persona.name}</h3>
            <p>{persona.description}</p>
        </div>
    </div>;
};

export const PersonasList: React.FC<PersonasListProps> = ({ personas, selectPersona }) => {
    return (
        <div className="personas-list">
            {personas.map(persona => <PersonaTile persona={persona} selectPersona={selectPersona} />)}
        </div>
    );
};
