import React from 'react';
import '../styles/TypingIndicator.scss';

const TypingIndicator = () => {
    return (
        <div className="typing-indicator">
            <div className="bubble">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    );
};

export default TypingIndicator;
