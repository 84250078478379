import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ChatWindow.scss';
import TypingIndicator from './TypingIndicator';

const ChatWindow = ({ persona, togglePersonas }: any) => {
    const [input, setInput] = useState('');
    const [sendingPaused, setSendingPaused] = useState(false);
    const [messages, setMessages] = useState([] as any[]);

    const agentName = persona?.name || 'Assistant';

    useEffect(() => {
        setMessages([]);
    }, [persona])

    const sendMessage = async () => {
        if (input.trim() === '') {
            return;
        }
        const updatedMessages = [...messages, { role: 'user', content: input }];
        setMessages(updatedMessages);
        setInput('');
        setSendingPaused(true);
        try {
            const response = await axios.post(`${window.location}api/chat/send`, {
                messages: updatedMessages,
                personaId: persona.id
            });
            setMessages([...updatedMessages, { role: 'assistant', content: response.data.response }]);
            setSendingPaused(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !sendingPaused) {
            sendMessage();
        }
    };

    return (
        <div className="chat-window">
            <div className='message-list-wrapper'>
                {
                    messages && messages.length > 0
                        ? <div className="message-list">
                            {
                                messages.map((msg, index) => (
                                    <div key={index} className={`message ${msg.role}`}>
                                        {
                                            msg.role === 'assistant'
                                            && <img className={`profile-image ${msg.role}`} src={persona?.id ? `/profile-photos/${persona.id}.png` : '/agent-profile.png'} alt={agentName} />
                                        }
                                        <div className='message-content-wrapper'>
                                            <span className='username'>{msg.role === 'assistant' ? persona?.name || 'Assistant' : 'You'}</span>
                                            <div className={`message-content ${msg.role}`}>{msg.content}</div>
                                        </div>
                                        {
                                            msg.role === 'user'
                                            && <img className={`profile-image ${msg.role}`} src='/user-profile.png' alt={'You'} />
                                        }
                                    </div>
                                ))
                            }
                            {
                                sendingPaused
                                && <div className='message assistant'>
                                    <img className={`profile-image assistant`} src={persona?.id ? `/profile-photos/${persona.id}.png` : '/agent-profile.png'} alt={agentName} />
                                    <div className='message-content-wrapper'>
                                        <span className='username'>{persona?.name || 'Assistant'}</span>
                                        <div className={`message-content assistant`}><TypingIndicator /></div>
                                    </div>
                                </div>
                            }
                        </div>
                        : <div className='empty-state'>
                            <img className='profile-image' src={`/profile-photos/${persona.id}.png`} alt={persona.name} />
                            <h3>{persona.name}</h3>
                            <p>{persona.description}</p>
                        </div>
                }
            </div>
            <div className="input-wrapper">
                <button className="toggle-personas-button" onClick={togglePersonas}>
                    <img src='icons/show-personas.png' alt='show personas icon' />
                </button>
                <input
                    type="text"
                    className='input-field'
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button className='send-button' disabled={sendingPaused} onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
};

export default ChatWindow;
